import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Checkout from "../../PropagoCheckout/App";
import DropdownLocale from "../../DropdownLocale";

// Styles
import { Wrapper, WrapperText, Image } from "./styles";

const Wireframe = ({ onlyCheckout = false }) => {
  const { t } = useTranslation();

  const CHECKOUT_ID = process.env.REACT_APP_CHECKOUT_ID;

  return (
    <Wrapper id="section-checkout">
      {onlyCheckout && (
        <Col className="wrapper-button-i18n">
          <DropdownLocale />
        </Col>
      )}
      {!onlyCheckout && t("CHEC00001.title") !== '' && (
        <Row className="justify-content-center g-0 h-100">
          <Col xs={10}>
            <WrapperText titleColor="#B35EB7" dangerouslySetInnerHTML={{ __html: t("CHEC00001.title") }}></WrapperText>
          </Col>
        </Row>
      )}
      <Row
        className={`justify-content-center g-0 row-checkout ${
          onlyCheckout ? "vh-100" : ""
        }`}
      >
        <Checkout checkoutID={CHECKOUT_ID} id="SectionCheckout" />
      </Row>
      {!onlyCheckout && (
        <Row className="justify-content-center g-0 h-100">
          <Col xs={10}>
            <WrapperText legendColor="#121441">
              {t("CHEC00001.legend")}
            </WrapperText>
          </Col>
          <Col xs={10} className="d-flex justify-content-center">
            <Image src="/assets/PROPAGO_LOGO.webp" width={173} height={36} alt='' />
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default Wireframe;
