import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;  
  ${({ backgroundColor }) => backgroundColor ? `
    background-color: ${backgroundColor};
  ` : `
    background-image: url(${({ backgroundImageURL }) => backgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
    backgroung-position: center;
  `}
  min-height: 550px;
  padding: 40px 0 40px 0;
  @media ${devices.md} {
    padding: 60px 0 60px 0;
  }

  .button {
    margin-top: 30px;
  }
  
  .image {
    max-height: 300px;
  }

  .cards {
    margin-top: 20px;
    @media ${devices.md} {
      margin-top: 40px;
      div {
        margin-right: 15px;
        margin-left: 15px;
      }
    }
  }
`;

export const WrapperCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 180px;
  padding: 20px 15px 20px 15px;
  margin-top: 50px;
  margin-bottom: 15px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  .title {
    position: absolute;
    top: -16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding: 4px;
    border-radius: 10px;
    background-color: ${({ titleBackgroundColor }) => titleBackgroundColor};
    color: ${({ titleColor }) => titleColor};
    font-weight: 700;
    text-align: center;
  }

  .content {
    text-align: center;
    font-size: 16px;
    color: ${({ contentColor }) => contentColor};
  }
`

export const WrapperText = styled.div`
  ${({ textColor }) => textColor ? `
    color: ${textColor};
    font-weight: 400;

    h2 {
      font-size: 28px;
      font-weight: bold;
      color: #B35EB7;
    }

    @media ${devices.xs} {
      margin-top: 40px;
      font-size: 18px;
      line-height: 22px;
    }

    @media ${devices.sm} {
      margin-top: 40px;
      font-size: 18px;
      line-height: 22px;
    }

    @media ${devices.md} {
      margin-top: 0;
    }
  `: ""}
`;