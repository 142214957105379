import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";


// Styles
import { Wrapper, WrapperCard, WrapperDescription, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const left = t("COMP00002.left", { returnObjects: true });

  return (
    <Wrapper 
      leftBackgroundImageURL="/assets/maos-estendidas/COMP00002-img.webp" 
      id="COMP00002"
    >
      <Row className="justify-content-center g-0 h-100">
        <Col
          xm={12}
          sm={12}
          md={6}
          className="d-flex flex-column justify-content-center align-items-center align-items-md-end col-left"
        >
          {_.map(left, (item, index) => (
            <WrapperCard 
              mainColor="#B35EB7" 
              numberColor="#FFFFFF" 
              cardBackgroundColor="#121441" 
              descriptionColor="#FFFFFF" 
              key={index}
            >
              <div className={`content ${_.isEmpty(_.get(item, "title")) ? 'content-center' : ''}`}>
                <div className="content-percent">{_.get(item, "title")}</div>
                <div className="content-description">
                  {_.get(item, "subTitle")}
                </div>
              </div>
            </WrapperCard>
          ))}
          <WrapperDescription descriptionColor="#121441" dangerouslySetInnerHTML={{ __html: t("COMP00002.leftLegend")}}>
          </WrapperDescription>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          className="d-flex flex-column justify-content-center align-items-center align-items-md-start col-right"
        >
          <Col
            xs={12}
            sm={12}
            md={10}
            lg={10}
            xl={9}
            className="d-flex justify-content-center"
          >
            {/* COLOCAR ÍCONE AQUI */}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={10}>
            <WrapperText
              titleColor="#B35EB7"
              className="text-center"
              dangerouslySetInnerHTML={{ __html: t("COMP00002.rightTitle") }}
            ></WrapperText>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={10}>
            <WrapperText
              descriptionColor="#121441"
              dangerouslySetInnerHTML={{
                __html: t("COMP00002.rightSubTitle1"),
              }}
            ></WrapperText>
          </Col>
          <Col xs={12} sm={12} md={10} lg={12} xl={10}>
            <WrapperText
              descriptionColor="#121441"
              dangerouslySetInnerHTML={{
                __html: t("COMP00002.rightSubTitle2"),
              }}
            ></WrapperText>
          </Col>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
