import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ bgColor }) => bgColor};
`;

export const WrapperTitle = styled.h2`
    padding: 2em 0 .5em;
    margin-bottom: 1em;
    color: ${({ color }) => color};
    font-size: 28px;
    font-weight: bold;
    line-height: 32px;
    text-align: center
`;

export const WrapperRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        padding: 2em;
        border: 3px solid #121441;
        border-radius: 10px;
        background-color: #FFFFFF;
    }

    .icon-container {
        padding: 0 2.5em;
    }

    :first-of-type {
        .icon-container {
            transform: rotate(270deg);
        }
    }

    :nth-of-type(even) {
        flex-direction: row-reverse;

        .column {
            @media ${devices.md} {
                padding-left: 0;
                padding-right: 2.5em;
            }
        }

        .icon-container {
            transform: scaleX(-1);
        }
    }

    @media ${devices.xs} {
        .icon-container {
            display: none;
        }
        .column{
            padding: 1em;
        }
    }
    @media ${devices.sm} {
        .icon-container {
            display: none;
        }
        .column {
            padding: 1em 2em;
        }
    }

    @media ${devices.md} {
        .icon-container{ 
            display: flex;
        }
    }
`

export const WrapperColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 2.5em;
    color: ${({ color }) => color};
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
`;