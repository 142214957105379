import _ from "lodash";
import { Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";

// Styles
import { Wrapper, WrapperText, WrapperCard } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const cards = t("COMP00022.cards", { returnObjects: true });

  return (
    <Wrapper 
      id="COMP00022"
    >
      <Row className="g-0">
        <Col xs={6} md={4} xl={3}className="d-flex justify-content-center align-items-center offset-3 offset-md-1 offset-xl-2">
          <Image 
            className="image" 
            src="/assets/maos-estendidas/COMP00022-img.webp" 
            width={'100%'} 
            height={'auto'} 
            alt={t("COMP00022.imgAlt")} 
            fluid
          />
        </Col>
        <Col xs={10} md={5} lg={5} xl={4} className="offset-1">
          <WrapperText textColor="#000000" dangerouslySetInnerHTML={{ __html: t("COMP00022.content") }}>
          </WrapperText>
          <Col xs={12} className="button d-flex justify-content-center">
            <Button
              primaryColor="#121441"
              secondaryColor="#FFFFFF"
              icon={false}
              buttonWidthVariable={true}
            >
              {t(`COMP00022.button`)}
            </Button>
          </Col>
        </Col>
      </Row>
      <Row className="g-0 cards justify-content-center">
        <Col xs={10} sm={7} md={3} xxl={2}>
          <WrapperCard
            backgroundColor="#FFFFFF"
            titleBackgroundColor="#B35EB7"
            titleColor="#FFFFFF"
            contentColor="#707070"
          >
            <div className="title">{_.get(cards, '0.title')}</div>
            <div className="content">{_.get(cards, '0.content')}</div>
          </WrapperCard>
        </Col>
        <Col xs={10} sm={7} md={3} xxl={2}>
          <WrapperCard
            backgroundColor="#FFFFFF"
            titleBackgroundColor="#B35EB7"
            titleColor="#FFFFFF"
            contentColor="#707070"
          >
            <div className="title">{_.get(cards, '1.title')}</div>
            <div className="content">{_.get(cards, '1.content')}</div>
          </WrapperCard>
        </Col>
        <Col xs={10} sm={7} md={3} xxl={2}>
          <WrapperCard
            backgroundColor="#FFFFFF"
            titleBackgroundColor="#B35EB7"
            titleColor="#FFFFFF"
            contentColor="#707070"
          >
            <div className="title">{_.get(cards, '2.title')}</div>
            <div className="content">{_.get(cards, '2.content')}</div>
          </WrapperCard>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
