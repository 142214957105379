import { Row, Col, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// Styles
import { Wrapper, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const cards = t("FAQS00001.cards", { returnObjects: true });

  return (
    <Wrapper 
      backgroundColor="#B35EB7" 
      primaryColor="#FFFFFF" 
      secondaryColor="#B35EB7" 
      textRegular={200}
    >
      <Row className="justify-content-center g-0 h-100">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <WrapperText
            titleColor="#FFFFFF"
            textRegular={400}
            dangerouslySetInnerHTML={{ __html: t("FAQS00001.title") }}
          ></WrapperText>
        </Col>
      </Row>
      <Row className="justify-content-center g-0 h-100 row-accordion">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Accordion defaultActiveKey="0" flush>
            {_.map(cards, (item, index) => (
              <Accordion.Item key={index} eventKey={`${index}`}>
                <Accordion.Header>
                  <div className="accordion-header">
                    <div className="number">
                      {`${index + 1}`.padStart(2, "0")}
                    </div>
                    <div className="bar" />
                    <div className="description">{_.get(item, "title")}</div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: _.get(item, "subTitle"),
                    }}
                  ></div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
