import styled from 'styled-components'
import devices from '../../../utils/helperGrids'

export const Wrapper = styled.footer`
  position: relative;
  
  background-color: ${({ backgroundColor }) => backgroundColor};

  .wrapper-copyright {
    background-color: ${({ copyrightBackgroundColor }) => copyrightBackgroundColor};
    padding-top: 18px;
    padding-bottom: 18px;
  }
  
  @media ${devices.xs} {
    .image {
      padding-top: 45px;
    }
    .wrapper-content {
      margin-top: 45px;
    }
    .wrapper-icons {
      margin-top: 45px;
      margin-bottom: 45px;
    }
    svg {
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  @media ${devices.sm} {
    .image {
      padding-top: 25px;
    }
    .wrapper-content {
      margin-top: 45px;
    }
    .wrapper-icons {
      margin-top: 45px;
      margin-bottom: 45px;
    }
    svg {
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  @media ${devices.md} {
    padding-bottom: 0px;
  }

  @media ${devices.lg} {
    .wrapper-content {
      margin-top: 0px;
      min-height: 300px
    }
    .wrapper-icons {
      margin-top: 40px;
      margin-bottom: 0px;
    }
    svg {
      margin-left: 6px;
      margin-right: 0px;
    }
  }
  

  svg {
    fill: ${({ socialIconsColor }) => socialIconsColor}
  }
`

export const WrapperText = styled.div`
  ${({ mainColor, textRegular }) => mainColor ? `
    font-size: 15px;
    line-height: 24px;
    font-weight: ${textRegular};
    color: ${mainColor};
    
    @media ${devices.xs} {
      text-align: center;
    }
  
    @media ${devices.sm} {
      text-align: center;
    }
  
    @media ${devices.lg} {
      text-align: right;
    }
    
    a {
      text-decoration: none;
      color: ${mainColor};
    }
    a:active {
      text-decoration: none;
      color: ${mainColor};
    }
    a:hover {
      text-decoration: none;
      color: ${mainColor};
    }
  ` : ''}

  ${({ copyrightColor, textRegular, mainColor }) => copyrightColor ? `
    font-size: 15px;
    line-height: 24px;
    font-weight: ${textRegular};
    color: ${copyrightColor ? copyrightColor : mainColor};
    text-align: center;

    a {
      text-decoration: underline;
      color: ${copyrightColor ? copyrightColor : mainColor};
    }
    a:active {
      text-decoration: underline;
      color: ${copyrightColor ? copyrightColor : mainColor};
    }
    a:hover {
      text-decoration: underline;
      color: ${copyrightColor ? copyrightColor : mainColor};
    }
  ` : ''}

  strong {
    font-weight: 600;
  }
`

export const Image = styled.img`
  object-fit: contain;
  max-width: 150px;
  ${({ lg }) => lg ? 'margin-top: 50px' : ''}
`