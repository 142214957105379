import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 280px;

  ${({ backgroundImageURL }) => backgroundImageURL ? `
    background-image: url(${backgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  ` : `
    background-color: ${({ backgroundColor }) => backgroundColor}
  `}

  .button {
    display: flex;
    justify-content: center;
  }
  padding-bottom: 20px;
`;

export const WrapperText = styled.div`
  ${({ titleColor }) =>
    titleColor
      ? `
      h2 {
        font-size: 28px;
        line-height: 32px;
        font-weight: 600;
        color: ${titleColor};
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    
  `
      : ""}

  ${({ descriptionColor }) =>
    descriptionColor
      ? `
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
    color: ${descriptionColor};
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;

    @media ${devices.xs} {
      font-size: 15px;
    }
  `
      : ""}
`;

export const WrapperImage = styled.img`
  width: 100%;
`;