import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Styles
import { Wrapper, WrapperText, WrapperImage } from "./styles";

const Wireframe = ({ imageRightURL = ''}) => {
  const { t } = useTranslation();

  return (
    <Wrapper
      backgroundImageURL="/assets/maos-estendidas/COMP00020-img.webp" 
      id="COMP00020"
    >
      <Row className="justify-content-center g-0 h-100">
        <Col xs={10} sm={8} md={7} lg={9} xl={9} className="h-100">
          <Row className="justify-content-center g-0 h-100">
            <Col
              xs={12}
              lg={8}
              xl={7}
              className="d-flex flex-column justify-content-center wrapper-content"
            >
              <WrapperText titleColor="#FFFFFF" dangerouslySetInnerHTML={{ __html: t("COMP00020.title") }}></WrapperText>
              <WrapperText descriptionColor="#FFFFFF" dangerouslySetInnerHTML={{ __html: t("COMP00020.subTitle") }}></WrapperText>
            </Col>
            {imageRightURL !== '' && (
              <Col xs={12} lg={4} xl={4}>
                <WrapperImage src={imageRightURL}></WrapperImage>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
