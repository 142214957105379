// Components
import { useTranslation } from "react-i18next";

// Styles
import { Wrapper, WrapperTitle } from "./styles";

const Wireframe = ( srcContent ) => {
    const { t } = useTranslation();

    return (
        <Wrapper backgroundColor="#121441">
            <WrapperTitle titleColor="#FFFFFF" className="mb-4">{t("COMP_GEOL.title")}</WrapperTitle>
            <iframe 
                src={srcContent.url} 
                title={t("COMP_GEOL.iframeTitle")} 
                referrerpolicy="no-referrer-when-downgrade" 
                width="100%" 
                height={500} 
                style={{ border: '4px solid #B35EB7'}} 
                loading="lazy" allowfullscreen=""
            ></iframe>
        </Wrapper>
    );
}

export default Wireframe;