import { useState } from "react";

// Components
import COMP00002 from "./components/Wireframes/COMP00002";
import COMP00004 from "./components/Wireframes/COMP00004";
import COMP00005 from "./components/Wireframes/COMP00005";
import COMP00008 from "./components/Wireframes/COMP00008";
import COMP00020 from "./components/Wireframes/COMP00020";
import COMP00022 from "./components/Wireframes/COMP00022";
import COMP00023 from "./components/Wireframes/COMP00023";
import COMP00024 from "./components/Wireframes/COMP00024";
import COMP00025 from "./components/Wireframes/COMP00025";
import COMP_ALLYA from "./components/Wireframes/COMP_ALLYA";
import CHEC00001 from "./components/Wireframes/CHEC00001";
import FAQS00001 from "./components/Wireframes/FAQS00001";
import COMP_GEOL from "./components/Wireframes/COMP_GEOL";
import FOOT00001 from "./components/Wireframes/FOOT00001";
import HEAD00004 from "./components/Wireframes/HEAD00004";

// Libs
import { BrowserRouter } from "react-router-dom";

// Components
import SectionCookies from "./components/SectionCookies";
import ButtonDonateNow from "./components/ButtonDonateNow";

// Translations
import { startI18N } from "./i18n";
import i18nTranslations from "./whitelabels/maosestendidas/i18n";

function App() {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  startI18N(i18nTranslations);

  return (
    <div className="lp-app notranslate">
      <HEAD00004/>
      <COMP00002/>
      <COMP00020/>
      <COMP00005/>
      <COMP00008/>
      <COMP00024/>
      <COMP00004/>
      <COMP00022/>
      <COMP00023/>
      <COMP00025/>
      <COMP_ALLYA/>
      <CHEC00001/>
      <FAQS00001/>
      <COMP_GEOL url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.2056528866196!2d-47.78022422459122!3d-21.183987878580197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b9bf43e95f9843%3A0x7b9ff8eec0519fc4!2sR.%20Jos%C3%A9%20Aissum%2C%20829%20-%20Parque%20dos%20Bandeirantes%2C%20Ribeir%C3%A3o%20Preto%20-%20SP%2C%2014090-370!5e0!3m2!1spt-BR!2sbr!4v1695392193418!5m2!1spt-BR!2sbr"/>
      <FOOT00001/>
      <ButtonDonateNow 
        primaryColor="#B35EB7" 
        secondaryColor="#FFFFFF" 
        borderFixedColor="#FFFFFF" 
        borderFixedHoverColor="#B35EB7" 
      />
      {SectionCookies != '' && acceptedCookies === false && (
        <SectionCookies onAccept={() => setAcceptedCookies(true)} />
      )}
    </div>
  );
}

const AppContainer = (props) => {
  return (
    <BrowserRouter>
      <App {...props} />
    </BrowserRouter>
  );
};

export default AppContainer;