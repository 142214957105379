import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";

// Utils
import { scrollTo } from "../../../utils/helperScrolls"

// Assets
import { ReactComponent as ArrowDown } from "../../../assets/svgs/arrowDown.svg";

// Styles
import { Wrapper, WrapperCard, ArrowWrapper, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();
  let cards = t("COMP00005.cards", { returnObjects: true });

  return (
    <Wrapper 
      backgroundImageTop="/assets/maos-estendidas/COMP00005-img.webp"
      primaryColorTop="#FFFFFF"
      backgroundColorBottom="#FFFFFF"
      arrowColorBottom="#ED1566"
      id="COMP00005"
    >
      <div className="block-top d-flex flex-column justify-content-center align-items-center">
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={6}
          className="d-flex justify-content-center"
        >
          <WrapperText titleColor="#FFFFFF" dangerouslySetInnerHTML={{ __html: t("COMP00005.title") }}>
          </WrapperText>
        </Col>
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={6}
          className="d-flex justify-content-center"
        >
          <WrapperText descriptionColor="#FFFFFF" dangerouslySetInnerHTML={{ __html: t("COMP00005.subTitle") }}>
          </WrapperText>
        </Col>
        <ArrowWrapper
          arrowColor="#FFFFFF"
          onClick={() => {
            scrollTo("COMP00005-cards");
          }}
        >
          <ArrowDown />
        </ArrowWrapper>
      </div>
      <div className="block-bottom" id="COMP00005-cards">
        <Row className="justify-content-center g-0 h-100">
          <Col xs={12} sm={12} md={12} lg={12} xl={10}>
            <Row className="justify-content-around g-0 h-100 row-cards">
              { _.map(cards, (item) => (
                  <Col
                    xs={10}
                    sm={10}
                    md={4}
                    lg={3}
                    className="d-flex justify-content-center"
                  >
                    <WrapperCard
                      mainColor="#121441"
                      numberColor="#FFFFFF"
                      cardBackgroundColor="#FFFFFF"
                      descriptionColor="#12441"
                    >
                      <div className="number">{item.title}</div>
                      <div className="content">
                        <div className="content-description">
                          {item.text}
                        </div>
                      </div>
                    </WrapperCard>
                  </Col>
                ))
              }
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center g-0 w-100 h-100 mt-5">
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="d-flex justify-content-center text-center"
            dangerouslySetInnerHTML={{ __html: t("COMP00005.textBottom") }}
          >
          </Col>
        </Row>
        <Row className="justify-content-center g-0 w-100 h-100 row-button">
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={4}
            className="d-flex justify-content-center"
          >
            <Button
              primaryColor="#B35EB7"
              secondaryColor="#FFFFFF"
              icon={false}
              buttonWidthVariable={true}
            >
              {t("COMP00005.button")}
            </Button>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default Wireframe;
