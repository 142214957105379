import styled from 'styled-components'
//import devices from '../../../utils/helperGrids'

export const Wrapper = styled.div`
  position: relative;
  background-color: #B35EB7;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .row-images {
    margin-top: 49px;
  }
  .row-cards {
    margin-top: 58px;
  }
  .row-button {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`

export const WrapperCard = styled.div`
  width: 300 px;
  margin: 0 20px 49px 20px;
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    min-height: 150px;
    border-radius: 10px;
    padding: 15px;
    background-color: ${({ cardBackgroundColor }) => cardBackgroundColor};
    text-align: center;
  }
  .content-percent {
    color: ${({ mainColor }) => mainColor};
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
  }
  .content-description {
    color: ${({ descriptionColor }) => descriptionColor};
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
`

export const WrapperText = styled.div`
  ${({ titleColor }) => titleColor ? `
  h2 {
    margin-bottom: 20px;
    color: ${titleColor};
    font-size: 28px;
    line-height: 32px;
    font-weight: 800;
    text-align: center;
  }` 
  : `
    font-size: 20px;
    line-height: 24px;
    color: #FFFF;
  `}
`

export const Image = styled.img`
  margin: 5px;
  object-fit: cover;
  width: 220px;
  height: 204px;
  border-radius: 10px;
`