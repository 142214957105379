import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  
  .col-left {
    padding: 2em 0 6em;
    background-image: url(${({ leftBackgroundImageURL }) => leftBackgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
    @media ${devices.md} {
      padding-right: 48px;
    }
  }

  .col-right {
    padding: 42px 48px;
    background-color: #FFFFFF;
  }
`;

export const Image = styled.img`
  margin-bottom: ${({ marginLogo }) => marginLogo};
`;

export const WrapperCard = styled.div`
  width: 265px;
  margin-bottom: 49px;

  .content {
    background-color: ${({ cardBackgroundColor }) => cardBackgroundColor};
    width: 265px;
    min-height: 178px;
    border-radius: 12px;
    padding: 18px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .content-center {
    justify-content: center;
  }
  .content-percent {
    color: ${({ mainColor }) => mainColor};
    font-size: 35px;
    font-weight: 600;
  }
  .content-description {
    color: ${({ descriptionColor }) => descriptionColor};
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
`;

export const WrapperDescription = styled.div`
  font-size: 16px;
  text-align: right;
  color: ${({ descriptionColor }) => descriptionColor};
  font-style: italic;
  padding-left: 60%;
  padding-right: 48px;
  text-align: center;

  @media ${devices.md} {
    padding-right: 0px;
  }
`;

export const WrapperText = styled.div`
  ${({ titleColor }) =>
    titleColor
      ? `
    font-size: 28px;
    line-height: 30px;
    font-weight: bold;
    color: ${titleColor};
  `
      : ""}

  ${({ descriptionColor }) =>
    descriptionColor
      ? `
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: ${descriptionColor};

    strong2 {
      font-size: 15px;
      font-weight: 400;
    }
  `
      : ""}
  margin-bottom: 30px;

  strong {
    font-weight: 600;
  }
`;
