import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
    @media ${devices.xs} {
        padding: 10em 1em 2em;
    }
    @media ${devices.sm} {
        padding: 2em;
    }
    @media ${devices.md} {
        padding: 6em 8em;
    }
`;

export const WrapperTitle = styled.h2`
    margin-bottom: .4em;
    color: ${({ titleColor }) => titleColor};
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;

    @media ${devices.xs} {
        font-size: 28px;
    }

    @media ${devices.md} {
        font-size: 2em;
    }
`;

export const WrapperDescription = styled.div`
    padding: 1em 0;
    font-size: 1.3em;
`;

export const RowContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media ${devices.xs} {
        flex-direction: column;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
`;

export const WrapperText = styled.div`
    max-width: 700px;
    margin: auto;
    padding: 1em 0;
    font-size: 1.3em;
    text-align: center;
`;