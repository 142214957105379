import i18n_translations from "../../../src/components/PropagoCheckout/i18n/translations";

const i18n = {
  "pt-BR": {
    translations: {
      Page: {
        title: "Mãos Estendidas A Esperança para Mulheres em Situação de Vulnerabilidade",
      },
      ButtonFloating: {
        button: "Doe Agora",
        buttonBottom: "Faça uma doação",
      },
      HEAD00004: {
        title: 
        `<h1>PROGRAMA DE MÃOS ESTENDIDAS</h1>
        <small>Restaurando vidas, Reconstruindo sonhos</small>`,
        subTitle:
          `<h2 class='text-center'>BRASIL ESTÁ DIANTE DE UM AUMENTO DE TODAS AS FORMAS DE  VIOLÊNCIA CONTRA A MULHER.</h2>
          <p>O Fórum Brasileiro de Segurança Pública, traz dados inéditos sobre diferentes formas de violência física, sexual e psicológica sofridas pelas brasileiras. Os dados permitem estimar que cerca de <strong>18,6 milhões de mulheres brasileiras</strong> foram vitimizadas no ano de 2022, o equivalente a <strong>um estádio de futebol com capacidade para 50 mil pessoas lotado todos os dias.</strong></p>
          <p><strong>Fonte:</strong> “Visível e Invisível: a Vitimização de Mulheres no Brasil” (Fórum Brasileiro de Segurança Pública 03/2023).</p>`,
        button: "Doe Agora",
      },
      COMP00002: {
        left: [
          {
            title: "49,7%",
            subTitle: "Foi o crescimento de registros de assédio sexual somente no ano de 2022, totalizando 6114 casos.",
          },
          {
            title: "37%",
            subTitle: "Foi o crescimento para casos de importunação sexual, atingindo o patamar de 27530 casos em 2022.",
          },
        ],
        leftLegend:
          "Fonte:<br/> Fórum Brasileiro de Segurança Pública",
        rightTitle: "A Situação das Mulheres no Brasil",
        rightSubTitle1:
          `<p>Os números revelam que:</p>
          <ul>
            <li>Os feminicídios cresceram 6,1% em 2022, resultando em 1.437 mulheres mortas simplesmente por serem mulheres;</li>
            <li>Os homicídios dolosos de mulheres também cresceram (1,2% em relação ao ano anterior);</li>
            <li>As agressões em contexto de violência doméstica tiveram aumento de 2,9%, totalizando 245.713 casos;</li>
            <li>As ameaças cresceram 7,2%, resultando em 613.529 casos;</li>
            <li>Os acionamentos ao 190, número de emergência da Polícia Militar, chegaram a 899.485 ligações, o que significa uma média de 102 acionamentos por hora.</li>
          </ul>`,
        rightSubTitle2:
          "Ou seja, estamos falando de um crescimento muito significativo e que perpassa todas as modalidades criminais, desde o assédio, até o estupro e os feminicídios.",
      },
      COMP00020: {
        title: "<h2>MULHERES TRANSFORMADAS, TRANSFORMAM!</h2>",
        subTitle: "JUNTE-SE A NÓS PARA FORTALECER  MULHERES A FIM DE ROMPEREM COM A SITUAÇÃO DE RISCO E  VIOLÊNCIA DOMÉSTICA EM RIBEIRÃO PRETO",
      },
      COMP00005: {
        title: "<h2>Toda mulher merece segurança e empoderamento</h2>",
        subTitle:
          `<p>Bem-vindo ao Programa De Mãos Estendidas, um farol de esperança e apoio a mulheres que suportaram a escuridão da violência doméstica e outras formas de abuso em Ribeirão Preto, São Paulo.</p>
          <p>Você sabia que o Brasil tem um dos maiores índices de violência contra a mulher no mundo? Surpreendentemente, <strong>uma em cada três brasileiras com mais de 16 anos sofreu violência física e sexual provocada por parceiro íntimo ao longo da vida.</strong> Somado a violência psicológica são mais de 23,5 milhões de mulheres vítimas de violência física e/ou sexual por parte de parceiros íntimos ou ex-companheiros, representando <strong>43% da população feminina do país</strong>.</p>`,
        cards: [
          {
            title: 'No Que Acreditamos?',
            text: 'Acreditamos que toda mulher tem o direito de viver uma vida livre de medo e dor. Nós podemos fazer a diferença!',
          },
          {
            title: 'Nossa Missão',
            text: 'Nossa missão é fornecer um espaço seguro onde as sobreviventes possam se fortalecer e valorizar para prosperar em novo projeto de vida, descobrindo suas potencialidades.',
          },
          {
            title: 'Nossa Atuação',
            text: 'Desenvolvemos ações planejadas, contínuas, permanentes e gratuitas através da atuação de profissionais dedicados e experientes, determinados a remodelar a trajetória de vidas destas mulheres.'
          }
        ],
        textBottom: '<strong class="textBottom">Imagine um mundo onde cada MULHER seja tratada com igualdade e dignidade? Este é o mundo que queremos!</strong>',
        button: "DOE. VOCÊ TRANSFORMA VIDAS",
      },
      COMP00008: {
        title: "<h2>PME - PROGRAMA DE MÃOS ESTENDIDAS</h2>",
        subTitle:
          `<p>Fundada em 2007, há 16 anos, o Programa De Mãos Estendidas é uma associação sem fins lucrativos, sem vinculação partidária, na qual suas atividades são frutos do esforço e trabalho de uma rede de apoiadores e equipe de profissionais.</p>
          <p>Atendemos mulheres e suas famílias prioritariamente que residam nas comunidades de Ribeirão Preto, prestando a elas, serviços tipificado no SUAS - Sistema Único de Assistência Social: (a) Proteção e Garantia de Direitos e, (b) Convivência e Fortalecimento de Vínculos. É bastante alto o índice da violência contra a mulher em Ribeirão Preto, motivo que a atuação especializada do PME passa ser ainda mais relevante não somente para a MULHER que sofre mas como para sua família inteira.</p>
          <p>Prestamos acolhimento, uma escuta empática e ativa, proporcionando o suporte psicológico, social, jurídico e comunitário, integrando a rede de apoio e atenção para essas mulheres que apresentam viver em risco e  vulnerabilidade social.</p>
          <p class='text-center'><strong>QUEM CUIDA DA MULHER, CUIDA DA FAMÍLIA INTEIRA</strong></p>`,
        button: "Doe agora",
      },
      COMP00024: {
        title: "<h2>CONHEÇA NOSSOS PROJETOS</h2>",
        cards: [
          { 
            title: `<h3>1 - PROJETO MULHER ÚNICA</h3>
            <p>Nossa abordagem é multidisciplinar  com  atendimentos que  combinam  acolhimentos individuais psicossociais, orientações psicológicas, planejamento familiar,  e através da  realização de grupos de vivência com metodologia da arteterapia e terapia ocupacional, que estabelece o vínculo das participantes com nossa equipe visando (a) ofertar espaço seguro para trocas de vivências,  (b)nutrir  o bem-estar emocional e mental, (c)trazer a segurança de uma rede de apoio para ela resolver a situação, (4)promover saberes. </p>
            <h4>1.1 Apoio Jurídico</h4>
            <p>Nossa equipe jurídica orienta as mulheres que sofreram violência doméstica em todo o processo legal, garantindo que seus direitos sejam respeitados e que a justiça seja feita. Promove campanhas e palestras de conhecimento sobre os direitos da mulher e a Lei Maria da Penha.</p>`,
            alt: "Lorem ipsum"
          },
          { 
            title: `<h3>2 - PROJETO TALENTOS</h3>
            <p>Ofertamos oficinas de capacitação para o mercado de trabalho na área de artesanatos, beleza e moda.</p>
            <p>As mulheres descobrem  habilidades e percebem que a autonomia financeira é um impulso para a dignidade que buscam viver.</p>`,
            alt: "Dolor sit"
          },
          { 
            title: `<h3>3 - PROJETO OPERAÇÃO RESGATE</h3>
            <p>Desenvolvemos palestras sobre o direito da mulher no cárcere, de modo que, essas mulheres que estão privadas de liberdade, consigam discernir os mitos e crenças violadoras de direitos no momento da ressocialização.</p>`,
            alt: "Amet, consequitetur"
          },
          { 
            title: `<h3>4 - PROJETO PH</h3>
            <p>Através de uma equipe especializada acolhemos os autores de violência ( intramuros e extramuros do sistema penitenciário) para rodas de escuta e trocas de experiências, com objetivo principal de desconstruir estereótipos e a masculinidade tóxica.</p>`,
            alt: "Adipiscin elit"
          },
        ]
      },
      COMP00004: {
        title: "<h2>NOSSOS NÚMEROS</h2>",
        cards: [
          { span: "16 ANOS DE ATUAÇÃO" },
          { span: "40 MULHERES ATENDIDAS/MÊS" },
          { span: "07 VOLUNTÁRIOS" },
          { span: "500 MULHERES AJUDADAS" },
          { span: "1000 FAMÍLIAS IMPACTADAS" },
          { span: "08 PROFISSIONAIS multidisciplinares" },
          { span: "100 palestras Direito Mulher" },
        ],
        bottomText: 
        `<p>Seu papel nesta jornada transformadora é vital. Ao contribuir para a nossa organização sem fins econômicos/lucrativos de convivência e fortalecimento de vínculos para  garantia e proteção de direitos da mulher, você se torna um <strong>farol de esperança</strong> para as mulheres.</p>
        <p class='text-center'><strong>Mulheres não querem dominar o mundo, MULHERES MELHORAM O MUNDO!</strong></p>`,
        button: "DOE AS MULHERES PRECISAM DE VOCÊ",
      },
      COMP00022: {
        content: 
        `<h2 class='text-center mb-3'>VEJA COMO VOCÊ PODE FAZER A DIFERENÇA</h2>
        <p><strong>Sua doação é um voto de confiança na capacidade de cada mulher de superar as adversidades.</strong></p>
        <p>Com a sua ajuda, podemos reescrever histórias de dor em histórias de resiliência e superação e ao mesmo tempo de exemplo de esperança para outras mulheres . Juntos podemos quebrar as cadeias da violência e preparar para um futuro mais brilhante e seguro para todas as mulheres em Ribeirão Preto.</p>
        <p>Junte-se a nós nesta jornada transformadora de <strong>empoderamento feminino.</strong></p>`,
        button: "SEJA A MUDANÇA, DOE",
        imgAlt: "Lorem Ipsum",
        cards: [
          { 
            title: 'R$ 100,00', 
            content: 'Ajuda nos gastos mensais com alimentação servida nas atividades internas, bem como, entrega de alimentos básicos e itens de higiene íntima para as mulheres atendidas.' 
          },
          { 
            title: 'R$ 300,00', 
            content: 'Ajuda a financiar um profissional que atende uma mulher em sua jornada de fortalecimento e autonomia. São eles psicólogos, assistente social, advogados, terapeuta ocupacional, arteterapeuta.' 
          },
          { 
            title: 'R$ 200,00', 
            content: 'Apoia na aquisição de materiais para o trabalho de  mulheres capacitadas para o mercado de trabalho na área do artesanato, beleza e moda.'
          },
        ]
      },
      COMP00023: {
        title: '<h2>QUEM SE BENEFICIA COM AS ATIVIDADES DO PROGRAMA DE MÃOS ESTENDIDAS</h2>',
        line1: 
        `<p>Mulheres que sofreram violência doméstica e mulheres que estão vivendo algum risco social:</p>
        <ul>
          <li>Somos um recurso importante para que essas mulheres tenham uma oportunidade real de reerguer e recomeçar.</li>
        </ul>`,
        line2: 
        `<p>Famílias de mulheres que sofreram violência doméstica:</p>
        <ul>
          <li>Ajudar mulheres que sofrem violência doméstica implica também em colaborar e cooperar com a família estendida.</li>
        </ul>`,
      },
      COMP00025: {
        title: 'SOBRE OS FUNDADORES',
        rows: [
          {
            content: `<p>A fundação da Associação Programa de Mãos Estendidas aconteceu por vocação da família “Fernandes” cujo propósito é estar a serviço do amor e da vida, promovendo justiça social para as famílias em exclusão social  de modo  que seus direitos sejam garantidos e respeitados.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>Teve início como uma instituição responsável pelo processo de socialização das crianças, trabalhando com evangelismo na formação do caráter através da vivência de valores universais no contraturno escolar. Durante  11 anos trabalhando com o público infanto juvenil, atendeu mais de 1500 crianças com atividades diárias de cultura e lazer, oficinas pedagógicas, mas estendeu seus esforços para acolhimento de mulheres em situação de risco social a partir de 2015.  Entenderam os fundadores, nessa jornada,  que cuidar da mulher é também cuidar das crianças.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>Com a sede do PME instalada em uma comunidade de Ribeirão Preto chamada Monte Alegre,  com número expressivo de famílias vivendo em situação de pobreza e criminalidade, criaram projetos para atender todos os atores da família; o projeto Operação Resgate olhava para os pais e ou mães privados de liberdade; o projeto Talentos olhava para capacitação das mulheres no mercado da costura e artesanato,  o Projeto Só o Amor Transforma acolhia as crianças de 6 a 16 anos na convivência e fortalecimento de vínculos.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>A partir de 2015 a instituição decidiu através da deliberação de seus diretores envidar todo esforço para prestar serviços ao público feminino quando nasceu o projeto MULHER ÚNICA.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>O fundador (<em>in memoriam</em>) foi missionário nas prisões reconhecido pela Secretaria de Administração Penitenciária do Estado de São Paulo, e juntamente com sua esposa, filha e neta atualmente,  coordena os projetos e busca mantenedores para preservá-los.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
        ]
      },
      COMP_ALLYA: {
        title: 'Venha fazer parte do nosso ecossistema do bem!',
        description: `
          <p>O processo de doação é simples, seguro e fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Você pode doar uma única vez ou todos os meses, de forma recorrente.</p>
          <p><strong>Doe todos os meses</strong> para o Programa de Mãos Estendidas e participe do nosso <strong>Clube do Bem</strong>. Nele você <strong>ganha</strong> uma série de <strong>descontos, vantagens e benefícios</strong> em mais de 30 mil estabelecimentos diversos em todo país, podendo ganhar descontos em:</p>
          <ul>
            <li>Ingressos de cinema;</li>
            <li>Academias;</li>
            <li>Medicamentos;</li>
            <li>Cursos (inclusive graduações);</li>
            <li>Restaurantes</li>
            <li>e muito mais!</li>
          </ul>
          <p>Legal, né? Afinal de contas, doar faz bem!</p>
          `,
        text: `A <strong style="color: #F73D6A;">Allya</strong> tem como objetivo gerar economia e praticidade no dia a dia. Com seus benefícios, é possível manter um estilo de vida mais econômico e inteligente.`,
      },
      CHEC00001: {
        title: "Sua doação irá <strong>transformar a vida</strong> de uma mulher.",
        legend: "Apoio",
      },
      FAQS00001: {
        title: "<strong>Perguntas Frequentes</strong> sobre a doação:",
        cards: [
          {
            title: "Devo declarar as doações no imposto de renda?",
            subTitle:
              "Em conformidade com as regras da Receita Federal do Brasil, o doador precisa informar todas as doações realizadas em suas declarações financeiras. Lembrando que, segundo o Regulamento do Imposto de Renda – RIR (Decreto nº 9.580, de 22/11/ 2018), não há dedução no Imposto de Renda de Pessoa Física (IRPF) para doações a projetos como os realizados pelo Programa de Mãos Estendidas.<br /><br />O valor doado deve ser declarado na ficha DOAÇÕES EFETUADAS sob o código 99 – Outras doações, uma vez que se trata de doação não incentivada (não permite dedução do IR a pagar do doador).",
          },
          {
            title: "Quais são as formas de pagamento para doações no site?",
            subTitle:
              "Você pode fazer sua doação, seja ela pontual ou recorrente, via boleto bancário, cartão de crédito (todas as bandeiras) ou via pix.",
          },
          {
            title: "Por que o Programa de Mãos Estendidas emitiu uma cobrança em meu nome?",
            subTitle:
              "Para que os pagamentos sejam mais transparentes e seguros, a Federação Brasileira de Bancos (Febraban) instituiu que todos os boletos sejam registrados em uma plataforma de cobrança desenvolvida pela própria Febraban, que permite aos bancos informar ao cliente toda vez que um novo boleto for emitido em seu nome. Clientes cadastrados no serviço Débito Direto Autorizado (DDA) podem ser informados pelos bancos sobre a cobrança no momento do registro do boleto, pelos canais digitais ou com o envio de torpedos.",
          },
          {
            title: "Se eu não pagar o boleto, meu nome pode ser negativado?",
            subTitle:
              "Não. O pagamento dos boletos enviados pelo Programa de Mãos Estendidas é totalmente facultativo.",
          },
          {
            title:
              "Meu boleto veio com um valor que eu não quero doar, o que devo fazer?",
            subTitle:
              "Você pode escolher algum dos valores sugeridos para doação, ou definir uma outra quantia que seja de sua preferência, clicando no botão “outro valor”. Se o valor do boleto emitido não for igual ao que você pretende doar, pode emitir outro.",
          },
          {
            title: "O que eu faço se meu boleto vier sem código de barras?",
            subTitle:
              "Se você recebeu um boleto sem código de barras, isso indica que você está cadastrado no Débito Direto Autorizado (DDA). Por determinação das novas regras da Febraban, clientes cadastrados no serviço DDA não recebem mais boletos com códigos de barras. Para pagar seu boleto basta autorizar o débito junto ao seu banco, pela internet, pelo telefone ou nos caixas eletrônicos.",
          },
          {
            title: "O que é DDA?",
            subTitle:
              "O Débito Direto Autorizado (DDA) é um sistema criado pela Federação Brasileira de Bancos (Febraban) que substitui a emissão de boletos de cobrança impressos pela cobrança eletrônica dos pagamentos. Para ler o comunicado no site da Febraban, acesse: <a href='https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda' target='_blank'>https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda</a>",
          },
          {
            title: "Como posso me cadastrar no DDA?",
            subTitle:
              "Isso é um serviço oferecido pelos bancos. Para se cadastrar no Débito Direto Autorizado (DDA), basta entrar em contato com o seu banco, por telefone, pela internet ou nos caixas eletrônicos. Mesmo após o cadastro, você continua recebendo o boleto impresso; porém, sem o código de barras.",
          },
          {
            title: "Posso me descadastrar do DDA?",
            subTitle: "Para se descadastrar, entre em contato com o seu banco.",
          },
          {
            title:
              "Quero pagar meu boleto, mas a data de vencimento passou. Preciso emitir segunda via?",
            subTitle:
              "Não. Agora os boletos vencidos podem ser pagos em qualquer banco, pela internet, nos caixas eletrônicos ou em casas lotéricas.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visite-nos!`,
        iframeTitle: "Localização do escritório de Programa de Mãos Estendidas em Ribeirão Preto"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Programa de Mãos Estendidas ® - Todos os direitos podem ser compartilhados com você.",
        copyrightSpan: "Pergunte-nos como.",
      },
      SectionCookies: {
        title: "O Programa de Mãos Estendidas se preocupa com você e com a sua privacidade",
        subTitle:
          "O nosso site usa cookies e outras tecnologias para personalizar a sua experiência e compreender como você e os outros visitantes usam o nosso site. Ao navegar pelo site, coletaremos tais informações para utilizá-las com estas finalidades. Caso não aceite, usaremos os cookies apenas necessários para o correto funcionamento do site",
        button: "Aceito",
      },
      Checkout: i18n_translations["pt-BR"].translations,
    },
  },
  "en-US": {
    translations: {
      Page: {
        title: "Mãos Estendidas Hope for Women in Vulnerable Situations"
      },
      ButtonFloating: {
        button: 'Donate Now',
        buttonBottom: 'Make a donation'
      },
      HEAD00004: {
        title: 
        `<h1>MÃOS ESTENDIDAS PROGRAM</h1>
        <small>Restoring lives, Rebuilding dreams</small>`,
        subTitle:
          `<h2 class='text-center'>BRAZIL IS FACING AN INCREASE IN ALL FORMS OF VIOLENCE AGAINST WOMEN.</h2>
          <p>The Brazilian Public Security Forum brings unprecedented data on different forms of physical, sexual, and psychological violence suffered by Brazilian women. The data allows us to estimate that about <strong>18.6 million Brazilian women</strong> were victimized in 2022, equivalent to <strong>a football stadium with a capacity of 50 thousand people crowded every day.</strong></p>
          <p><strong>Source:</strong> "Visible and Invisible: Women's Victimization in Brazil" (Brazilian Public Security Forum 03/2023).</p>`,
        button: "Donate Now",
      },
      COMP00002: {
        left: [
          {
            title: "49.7%",
            subTitle: "Was the growth in reports of sexual harassment in 2022 alone, totaling 6114 cases.",
          },
          {
            title: "37%",
            subTitle: "Was the growth for cases of sexual harassment, reaching the level of 27530 cases in 2022.",
          },
        ],
        leftLegend:
          "Source:<br/> Brazilian Public Security Forum",
        rightTitle: "The Situation of Women in Brazil",
        rightSubTitle1:
          `<p>The numbers reveal that:</p>
          <ul>
            <li>Femicides grew by 6.1% in 2022, resulting in 1,437 women killed simply for being women;</li>
            <li>Intentional homicides of women also grew (1.2% compared to the previous year);</li>
            <li>Aggressions in the context of domestic violence increased by 2.9%, totaling 245,713 cases;</li>
            <li>Threats increased by 7.2%, resulting in 613,529 cases;</li>
            <li>Calls to 190, the emergency number of the Military Police, reached 899,485 calls, which means an average of 102 calls per hour.</li>
          </ul>`,
        rightSubTitle2:
          "That is, we are talking about a very significant growth that permeates all criminal modalities, from harassment to rape and femicides.",
      },
      COMP00020: {
        title: "<h2>TRANSFORMED WOMEN, TRANSFORM!</h2>",
        subTitle: "JOIN US TO STRENGTHEN WOMEN TO BREAK THE SITUATION OF RISK AND DOMESTIC VIOLENCE IN RIBEIRÃO PRETO",
      },
      COMP00005: {
        title: "<h2>Every woman deserves safety and empowerment</h2>",
        subTitle:
          `<p>Welcome to the Mãos Estendidas Program, a beacon of hope and support for women who have endured the darkness of domestic violence and other forms of abuse in Ribeirão Preto, São Paulo.</p>
          <p>Did you know that Brazil has one of the highest rates of violence against women in the world? Surprisingly, <strong>one in three Brazilian women over the age of 16 has suffered physical and sexual violence caused by an intimate partner throughout their lives.</strong> Added to psychological violence, there are more than 23.5 million women who are victims of physical and/or sexual violence by intimate or former partners, representing <strong>43% of the female population in the country.</strong></p>`,
        cards: [
          {
            title: 'What We Believe In?',
            text: 'We believe that every woman has the right to live a life free from fear and pain. We can make a difference!',
          },
          {
            title: 'Our Mission',
            text: 'Our mission is to provide a safe space where survivors can strengthen and value themselves to thrive in a new life project, discovering their potentialities.',
          },
          {
            title: 'Our Performance',
            text: 'We develop planned, continuous, permanent, and free actions through the performance of dedicated and experienced professionals, determined to remodel the trajectory of these women\'s lives.'
          }
        ],
        textBottom: '<strong class="textBottom">Imagine a world where every WOMAN is treated with equality and dignity? This is the world we want!</strong>',
        button: "DONATE. YOU TRANSFORM LIVES",
      },
      COMP00008: {
        title: "<h2>MEP - MÃOS ESTENDIDAS PROGRAM</h2>",
        subTitle:
          `<p>Founded in 2007, 16 years ago, the Mãos Estendidas Program is a non-profit association, without party affiliation, in which its activities are the result of the effort and work of a network of supporters and a team of professionals.</p>
          <p>We prioritize serving women and their families who reside in the communities of Ribeirão Preto, providing them with services typified in SUAS - (Unified Social Assistance System in Portuguese): (a) Protection and Guarantee of Rights, and (b) Coexistence and Strengthening of Bonds. The rate of violence against women in Ribeirão Preto is quite high, which makes the specialized performance of MEP even more relevant not only for the WOMAN who suffers but also for her entire family.</p>
          <p>We provide support, empathetic and active listening, providing psychological, social, legal, and community support, integrating the support network and attention to these women who live at risk and social vulnerability.</p>
          <p class='text-center'><strong>WHO TAKES CARE OF WOMEN, TAKES CARE OF THE WHOLE FAMILY</strong></p>`,
        button: "Donate now",
      },
      COMP00024: {
        title: "<h2>GET TO KNOW OUR PROJECTS</h2>",
        cards: [
          { 
            title: `<h3>1 - UNIQUE WOMAN PROJECT</h3>
            <p>Our approach is multidisciplinary with services that combine individual psychosocial support, psychological guidance, family planning, and through the realization of experiential groups with art therapy and occupational therapy methodology, which establishes the bond of participants with our team aiming to (a) offer a safe space for sharing experiences, (b) nurture emotional and mental well-being, (c) bring the security of a support network for her to resolve the situation, (4) promote knowledge. </p>
            <h4>1.1 Legal Support</h4>
            <p>Our legal team guides women who have suffered domestic violence throughout the legal process, ensuring that their rights are respected and justice is served. It promotes campaigns and lectures on women's rights and the Maria da Penha Law.</p>`,
            alt: "Lorem ipsum"
          },
          { 
            title: `<h3>2 - TALENTS PROJECT</h3>
            <p>We offer training workshops for the job market in the areas of crafts, beauty, and fashion.</p>
            <p>Women discover skills and realize that financial autonomy is an impulse for the dignity they seek to live.</p>`,
            alt: "Dolor sit"
          },
          { 
            title: `<h3>3 - RESCUE OPERATION PROJECT</h3>
            <p>We develop lectures on women's rights in prison so that these women who are deprived of their freedom can discern myths and beliefs that violate rights at the moment of resocialization.</p>`,
            alt: "Amet, consequitetur"
          },
          { 
            title: `<h3>4 - PROJETO PH</h3>
            <p>Através de uma equipe especializada acolhemos os autores de violência ( intramuros e extramuros do sistema penitenciário) para rodas de escuta e trocas de experiências, com objetivo principal de desconstruir estereótipos e a masculinidade tóxica.</p>`,
            alt: "Adipiscin elit"
          },
        ]
      },
      COMP00004: {
        title: "<h2>OUR NUMBERS</h2>",
        cards: [
          { span: "16 YEARS OF OPERATION" },
          { span: "40 WOMEN SERVED/MONTH" },
          { span: "07 VOLUNTEERS" },
          { span: "500 WOMEN HELPED" },
          { span: "1000 FAMILIES IMPACTED" },
          { span: "08 multidisciplinary PROFESSIONALS" },
          { span: "100 Women's Rights lectures" },
        ],
        bottomText: 
        `<p>Your role in this transformative journey is vital. By contributing to our non-profit organization of coexistence and strengthening of bonds for the guarantee and protection of women's rights, you become a <strong>beacon of hope</strong> for women.</p>
        <p class='text-center'><strong>Women don't want to dominate the world, WOMEN IMPROVE THE WORLD!</strong></p>`,
        button: "DONATE WOMEN NEED YOU",
      },
      COMP00022: {
        content: 
        `<h2 class='text-center mb-3'>SEE HOW YOU CAN MAKE A DIFFERENCE</h2>
        <p><strong>Your donation is a vote of confidence in each woman's ability to overcome adversity.</strong></p>
        <p>With your help, we can rewrite stories of pain into stories of resilience and overcoming and at the same time an example of hope for other women. Together we can break the chains of violence and prepare for a brighter and safer future for all women in Ribeirão Preto.</p>
        <p>Join us on this transformative journey of <strong>women's empowerment.</strong></p>`,
        button: "BE THE CHANGE, DONATE",
        imgAlt: "Lorem Ipsum",
        cards: [
          { 
            title: 'R$ 100.00', 
            content: 'Helps with monthly expenses for food served in internal activities, as well as, delivery of basic food and intimate hygiene items for the women served.' 
          },
          { 
            title: 'R$ 300.00', 
            content: 'Helps to finance a professional who attends a woman on her journey of strengthening and autonomy. They are psychologists, social workers, lawyers, occupational therapists, art therapists.' 
          },
          { 
            title: 'R$ 200.00', 
            content: 'Supports the acquisition of materials for the work of women trained for the job market in the area of handicrafts, beauty and fashion.'
          },
        ]
      },
      COMP00023: {
        title: '<h2>WHO BENEFITS FROM THE ACTIVITIES OF THE MÃOS ESTENDIDAS PROGRAM</h2>',
        line1: 
        `<p>Women who have suffered domestic violence and women who are living some social risk:</p>
        <ul>
          <li>We are an important resource for these women to have a real opportunity to rise and start over.</li>
        </ul>`,
        line2: 
        `<p>Families of women who have suffered domestic violence:</p>
        <ul>
          <li>Helping women who suffer domestic violence also implies collaborating and cooperating with the extended family.</li>
        </ul>`,
      },
      COMP00025: {
        title: 'ABOUT THE FOUNDERS',
        rows: [
          {
            content: `<p>The foundation of the Mãos Estendidas Program Association happened by vocation of the "Fernandes" family whose purpose is to be at the service of love and life, promoting social justice for families in social exclusion so that their rights are guaranteed and respected.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>It started as an institution responsible for the socialization process of children, working with evangelism in character formation through the experience of universal values in school counter-time. For 11 years working with the child and youth public, it served more than 1500 children with daily cultural and leisure activities, pedagogical workshops, but extended its efforts to welcome women in social risk situations from 2015. The founders understood on this journey that taking care of women is also taking care of children.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>With the MEP headquarters installed in a community in Ribeirão Preto called Monte Alegre, with a significant number of families living in a situation of poverty and criminality, they created projects to serve all family actors; the Rescue Operation project looked at parents or mothers deprived of freedom; the Talents project looked at training women in the sewing and handicraft market, the Only Love Transforms Project welcomed children from 6 to 16 years old in coexistence and strengthening of bonds.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>From 2015 the institution decided through the deliberation of its directors to make every effort to provide services to the female public when the UNIQUE WOMAN project was born.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>The founder (<em>in memoriam</em>) was a missionary in prisons recognized by the Secretary of Penitentiary Administration of the State of São Paulo, and together with his wife, daughter and granddaughter currently, coordinates the projects and seeks maintainers to preserve them.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
        ]
      },
      COMP_ALLYA: {
        title: 'Come and join our ecosystem of good!',
        description: `
          <p>The donation process is simple, safe and easy. The money donated goes towards our social and philanthropic actions. You can donate once or every month on a recurring basis.</p>
          <p><strong>Donate every month</strong> to Mãos Estendidas Program and take part in our <strong>Clube do Bem (Good Club)</strong>. Here <strong>you get a series of discounts, advantages and benefits</strong> at over 30,000 different establishments throughout Brazil in:</p>
          <ul>
            <li>Cinema tickets;</li>
            <li>Gymns;</li>
            <li>Medicines;</li>
            <li>Courses (including undergraduate degrees);</li>
            <li>Restaurants</li>
            <li>and a lot more!</li>
          </ul>
          <p>Cool, right? After all, donating is good for you!</p>
          `,
        text: `<strong style="color: #F73D6A;">Allya</strong> aims to generate savings and practicality in everyday life. With its benefits, it is possible to maintain a more economical and intelligent lifestyle.`,
      },
      CHEC00001: {
        title: "Your donation will <strong>change a woman's life</strong>",
        legend: "By",
      },
      FAQS00001: {
        title: "<strong>Frequently Asked Questions</strong> about the donation:",
        cards: [
          {
            title: "Does Mãos Estendidas Program accept donations in kind",
            subTitle:
              "Unfortunately, we cannot accept donations in kind.",
          },
          {
            title: "How can i be sure my online donation has been accepted?",
            subTitle:
              "If you entered a valid email address, you will receive a message confirming your donation. If you don't receive an email within about 15 minutes, please contact us.",
          },
          {
            title: "What are the payment methods for donations on the website?",
            subTitle: "You can make your donation, whether punctual or recurring by credit card."
          },
          {
            title:"Why is monthly giving the best option?",
            subTitle:
              "Monthly giving is the best option for both Mãos Estendidas Program and our supporters. It allows us to have a dependable base of support and provides predictable funding. For our supporters it is the easiest way to donate, since your credit card deducts your giving automatically.",
          },
          {
            title: "Is the donation page secure?",
            subTitle: "We are proud to meet strict data security standards in order to process your donation online.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visit us!`,
        iframeTitle: "Localization of Mãos Estendidas Program's office in Ribeirão Preto - SP (Brazil)"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Mãos Estendidas Program ® - All rights can be shared with you.",
        copyrightSpan: "Ask us how.",
      },
      SectionCookies: {
        title: "Mãos Estendidas Program worries about your privacy.",
        subTitle:
          "This website uses cookies and other technologies to improve your experience and to analyze the traffic in our website. Once you are browsing we will colect such information for this analysis. If you don't accept it, we will use only cookies that are needed to run the website.",
        button: "I Accept",
      },
      Checkout: i18n_translations.en.translations,
    }
  },
  es: {
    translations: {
      Page: {
        title: "Mãos Estendidas Esperanza Para Mujeres Vulnerables",
      },
      ButtonFloating: {
        button: "Dona ahora",
        buttonBottom: "Haz una donácion",
      },
      HEAD00004: {
        title: 
        `<h1>PROGRAMA DE MÃOS ESTENDIDAS</h1>
        <small>Restaurando vidas, Reconstruyendo sueños</small>`,
        subTitle:
          `<h2 class='text-center'>BRASIL ESTÁ ANTE UN AUMENTO DE TODAS LAS FORMAS DE VIOLENCIA CONTRA LA MUJER.</h2>
          <p>El Foro Brasileño de Seguridad Pública, trae datos inéditos sobre diferentes formas de violencia física, sexual y psicológica sufridas por las brasileñas. Los datos permiten estimar que alrededor de <strong>18,6 millones de mujeres brasileñas</strong> fueron victimizadas en el año 2022, lo equivalente a <strong>un estadio de fútbol con capacidad para 50 mil personas lleno todos los días.</strong></p>
          <p><strong>Fuente:</strong> “Visible e Invisible: la Victimización de Mujeres en Brasil” (Foro Brasileño de Seguridad Pública 03/2023).</p>`,
        button: "Donar Ahora",
      },
      COMP00002: {
        left: [
          {
            title: "49,7%",
            subTitle: "Fue el crecimiento de los registros de acoso sexual solo en el año 2022, totalizando 6114 casos.",
          },
          {
            title: "37%",
            subTitle: "Fue el crecimiento para casos de importunación sexual, alcanzando la cifra de 27530 casos en 2022.",
          },
        ],
        leftLegend:
          "Fuente:<br/> Foro Brasileño de Seguridad Pública",
        rightTitle: "La Situación de las Mujeres en Brasil",
        rightSubTitle1:
          `<p>Los números revelan que:</p>
          <ul>
            <li>Los feminicidios crecieron un 6,1% en 2022, resultando en 1.437 mujeres asesinadas simplemente por ser mujeres;</li>
            <li>Los homicidios dolosos de mujeres también crecieron (1,2% en relación al año anterior);</li>
            <li>Las agresiones en contexto de violencia doméstica tuvieron un aumento del 2,9%, totalizando 245.713 casos;</li>
            <li>Las amenazas crecieron un 7,2%, resultando en 613.529 casos;</li>
            <li>Las llamadas al 190, número de emergencia de la Policía Militar, llegaron a 899.485 llamadas, lo que significa un promedio de 102 llamadas por hora.</li>
          </ul>`,
        rightSubTitle2:
          "Es decir, estamos hablando de un crecimiento muy significativo y que abarca todas las modalidades criminales, desde el acoso hasta la violación y los feminicidios.",
      },
      COMP00020: {
        title: "<h2>MUJERES TRANSFORMADAS, ¡TRANSFORMAN!</h2>",
        subTitle: "ÚNETE A NOSOTROS PARA FORTALECER A LAS MUJERES PARA QUE PUEDAN ROMPER CON LA SITUACIÓN DE RIESGO Y VIOLENCIA DOMÉSTICA EN RIBEIRÃO PRETO",
      },
      COMP00005: {
        title: "<h2>Toda mujer merece seguridad y empoderamiento</h2>",
        subTitle:
          `<p>Bienvenido al Programa De Mãos Estendidas, un faro de esperanza y apoyo a mujeres que han soportado la oscuridad de la violencia doméstica y otras formas de abuso en Ribeirão Preto, São Paulo.</p>
          <p>¿Sabías que Brasil tiene una de las tasas más altas de violencia contra la mujer en el mundo? Sorprendentemente, <strong>una de cada tres brasileñas mayores de 16 años ha sufrido violencia física y sexual por parte de su pareja a lo largo de su vida.</strong> Sumado a la violencia psicológica, son más de 23,5 millones de mujeres víctimas de violencia física y/o sexual por parte de sus parejas o ex parejas, lo que representa el <strong>43% de la población femenina del país</strong>.</p>`,
        cards: [
          {
            title: '¿En qué creemos?',
            text: 'Creemos que todas las mujeres tienen derecho a vivir una vida libre de miedo y dolor. ¡Podemos marcar la diferencia!',
          },
          {
            title: 'Nuestra Misión',
            text: 'Nuestra misión es proporcionar un espacio seguro donde las sobrevivientes puedan fortalecerse y valorarse para prosperar en un nuevo proyecto de vida, descubriendo sus potencialidades.',
          },
          {
            title: 'Nuestra Actuación',
            text: 'Desarrollamos acciones planificadas, continuas, permanentes y gratuitas a través del trabajo de profesionales dedicados y experimentados, decididos a remodelar la trayectoria de vida de estas mujeres.'
          }
        ],
        textBottom: '<strong class="textBottom">Imagina un mundo donde cada MUJER sea tratada con igualdad y dignidad? ¡Este es el mundo que queremos!</strong>',
        button: "DONA. TRANSFORMAS VIDAS",
      },
      COMP00008: {
        title: "<h2>PME - PROGRAMA DE MÃOS ESTENDIDAS</h2>",
        subTitle:
          `<p>Fundada en 2007, hace 16 años, el Programa De Mãos Estendidas es una asociación sin fines lucrativos , sin vinculación partidaria, cuyas actividades son fruto del esfuerzo y trabajo de una red de apoyadores y equipo de profesionales.</p>
          <p>Atendemos a mujeres y sus familias prioritariamente que residen en las comunidades de Ribeirão Preto, proporcionándoles servicios tipificados en el SUAS - Sistema Único de Asistencia Social: (a) Protección y Garantía de Derechos y, (b) Convivencia y Fortalecimiento de Vínculos. Es bastante alto el índice de violencia contra la mujer en Ribeirão Preto, motivo por el cual la actuación especializada del PME se vuelve aún más relevante no solo para la MUJER que sufre sino también para toda su familia.</p>
          <p>Prestamos acogida, una escucha empática y activa, proporcionando el soporte psicológico, social, jurídico y comunitario, integrando la red de apoyo y atención para estas mujeres que presentan vivir en riesgo y vulnerabilidad social.</p>
          <p class='text-center'><strong>QUIEN CUIDA DE LA MUJER, CUIDA DE TODA LA FAMILIA</strong></p>`,
        button: "Donar ahora",
      },
      COMP00024: {
        title: "<h2>CONOCE NUESTROS PROYECTOS</h2>",
        cards: [
          { 
            title: `<h3>1 - PROYECTO MULHER ÚNICA</h3>
            <p>Nuestro enfoque es multidisciplinario con atenciones que combinan acogidas individuales psicosociales, orientaciones psicológicas, planificación familiar, y a través de la realización de grupos de vivencia con metodología de arteterapia y terapia ocupacional, que establece el vínculo de las participantes con nuestro equipo buscando (a) ofrecer espacio seguro para intercambios de vivencias, (b) nutrir el bienestar emocional y mental, (c) traer la seguridad de una red de apoyo para que ella resuelva la situación, (4) promover saberes. </p>
            <h4>1.1 Apoyo Jurídico</h4>
            <p>Nuestro equipo jurídico orienta a las mujeres que han sufrido violencia doméstica en todo el proceso legal, garantizando que sus derechos sean respetados y que se haga justicia. Promueve campañas y charlas de conocimiento sobre los derechos de la mujer y la Ley Maria da Penha.</p>`,
            alt: "Lorem ipsum"
          },
          { 
            title: `<h3>2 - PROYECTO TALENTOS</h3>
            <p>Ofrecemos talleres de capacitación para el mercado laboral en el área de artesanías, belleza y moda.</p>
            <p>Las mujeres descubren habilidades y perciben que la autonomía financiera es un impulso para la dignidad que buscan vivir.</p>`,
            alt: "Dolor sit"
          },
          { 
            title: `<h3>3 - PROYECTO OPERAÇÃO RESGATE</h3>
            <p>Desarrollamos charlas sobre el derecho de la mujer en prisión, de modo que estas mujeres que están privadas de libertad puedan discernir los mitos y creencias violadoras de derechos en el momento de la resocialización.</p>`,
            alt: "Amet, consequitetur"
          },
          { 
            title: `<h3>4 - PROYECTO PH</h3>
            <p>A través de un equipo especializado acogemos a los autores de violencia (intramuros y extramuros del sistema penitenciario) para ruedas de escucha e intercambios de experiencias, con objetivo principal de desmontar estereotipos y la masculinidad tóxica.</p>`,
            alt: "Adipiscin elit"
          },
        ]
      },
      COMP00004: {
        title: "<h2>NUESTROS NÚMEROS</h2>",
        cards: [
          { span: "16 AÑOS DE ACTUACIÓN" },
          { span: "40 MUJERES ATENDIDAS/MES" },
          { span: "07 VOLUNTARIOS" },
          { span: "500 MUJERES AYUDADAS" },
          { span: "1000 FAMILIAS IMPACTADAS" },
          { span: "08 PROFESIONALES multidisciplinares" },
          { span: "100 charlas Derecho Mujer" },
        ],
        bottomText: 
        `<p>Tu papel en esta jornada transformadora es vital. Al contribuir a nuestra organización sin fines económicos/lucrativos de convivencia y fortalecimiento de vínculos para garantizar y proteger los derechos de la mujer, te conviertes en un <strong>faro de esperanza</strong> para las mujeres.</p>
        <p class='text-center'><strong>Mujeres no quieren dominar el mundo, ¡MUJERES MEJORAN EL MUNDO!</strong></p>`,
        button: "DONA LAS MUJERES TE NECESITAN",
      },
      COMP00022: {
        content: 
        `<h2 class='text-center mb-3'>VEA CÓMO PUEDE HACER LA DIFERENCIA</h2>
        <p><strong>Su donación es un voto de confianza en la capacidad de cada mujer para superar las adversidades.</strong></p>
        <p>Con su ayuda, podemos reescribir historias de dolor en historias de resiliencia y superación y al mismo tiempo de ejemplo de esperanza para otras mujeres. Juntos podemos romper las cadenas de la violencia y preparar un futuro más brillante y seguro para todas las mujeres en Ribeirão Preto.</p>
        <p>Únase a nosotros en este viaje transformador de <strong>empoderamiento femenino.</strong></p>`,
        button: "SEA EL CAMBIO, DONE",
        imgAlt: "Lorem Ipsum",
        cards: [
          { 
            title: 'R$ 100,00', 
            content: 'Ayuda en los gastos mensuales con alimentación servida en las actividades internas, así como, entrega de alimentos básicos e ítems de higiene íntima para las mujeres atendidas.' 
          },
          { 
            title: 'R$ 300,00', 
            content: 'Ayuda a financiar un profesional que atiende a una mujer en su jornada de fortalecimiento y autonomía. Son ellos psicólogos, asistente social, abogados, terapeuta ocupacional, arteterapeuta.' 
          },
          { 
            title: 'R$ 200,00', 
            content: 'Apoya en la adquisición de materiales para el trabajo de mujeres capacitadas para el mercado laboral en el área del artesanato, belleza y moda.'
          },
        ]
      },
      COMP00023: {
        title: '<h2>QUIÉN SE BENEFICIA CON LAS ACTIVIDADES DEL PROGRAMA DE MÃOS ESTENDIDAS</h2>',
        line1: 
        `<p>Mujeres que han sufrido violencia doméstica y mujeres que están viviendo algún riesgo social:</p>
        <ul>
          <li>Somos un recurso importante para que estas mujeres tengan una oportunidad real de levantarse y empezar de nuevo.</li>
        </ul>`,
        line2: 
        `<p>Familias de mujeres que han sufrido violencia doméstica:</p>
        <ul>
          <li>Ayudar a mujeres que sufren violencia doméstica implica también colaborar y cooperar con la familia extendida.</li>
        </ul>`,
      },
      COMP00025: {
        title: 'SOBRE LOS FUNDADORES',
        rows: [
          {
            content: `<p>La fundación de la Asociación Programa de Mãos Estendidas ocurrió por vocación de la familia “Fernandes” cuyo propósito es estar al servicio del amor y de la vida, promoviendo justicia social para las familias en exclusión social de modo que sus derechos sean garantizados y respetados.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>Comenzó como una institución responsable del proceso de socialización de los niños, trabajando con evangelismo en la formación del carácter a través de la vivencia de valores universales en el contraturno escolar. Durante 11 años trabajando con el público infantojuvenil, atendió a más de 1500 niños con actividades diarias de cultura y ocio, talleres pedagógicos, pero extendió sus esfuerzos para acoger a mujeres en situación de riesgo social a partir de 2015. Entendieron los fundadores, en esta jornada, que cuidar de la mujer es también cuidar de los niños.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>Con la sede del PME instalada en una comunidad de Ribeirão Preto llamada Monte Alegre, con número expresivo de familias viviendo en situación de pobreza y criminalidad, crearon proyectos para atender a todos los actores de la familia; el proyecto Operação Resgate miraba a los padres y o madres privados de libertad; el proyecto Talentos miraba para capacitación de las mujeres en el mercado del costura y artesanato, el Proyecto Só o Amor Transforma acogía a los niños de 6 a 16 años en la convivencia y fortalecimiento de vínculos.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>A partir de 2015 la institución decidió a través de la deliberación de sus directores dedicar todo esfuerzo para prestar servicios al público femenino cuando nació el proyecto MULHER ÚNICA.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
          {
            content: `<p>El fundador (<em>in memoriam</em>) fue misionero en las prisiones reconocido por la Secretaría de Administración Penitenciaria del Estado de São Paulo, y junto con su esposa, hija y nieta actualmente, coordina los proyectos y busca mantenedores para preservarlos.</p>`,
            imgAlt: 'Lorem Ipsum'
          },
        ]
      },
      COMP_ALLYA: {
        title: '¡Venga y forme parte de nuestro ecosistema del bien!',
        description: `
          <p>El proceso de donación es sencillo, seguro y fácil. El dinero donado se destina a nuestras actividades sociales y filantrópicas. Puedes donar una vez o cada mes de forma periódica.</p>
          <p><strong>Colabore todos los meses</strong> con la Programa de Mãos Estendidas y participe en nuestro <strong>Clube do Bem (Club del Bien)</strong>, donde <strong>obtendrá una serie de descuentos, ventajas y beneficios</strong> en más de 30.000 establecimientos diferentes de todo el Brasil en:</p>
          <ul>
            <li>Entradas de cine;</li>
            <li>Gimnasios;</li>
            <li>Medicamentos;</li>
            <li>Cursos (incluidas las titulaciones);</li>
            <li>Restaurantes</li>
            <li>¡y mucho más!</li>
          </ul>
          <p>Genial, ¿verdad? Al fin y al cabo, ¡donar es bueno para usted!</p>
          `,
        text: `El objetivo de <strong style="color: #F73D6A;">Allya</strong> es generar ahorro y practicidad en la vida cotidiana. Con sus ventajas, es posible un estilo de vida más económico e inteligente`,
      },
      CHEC00001: {
        title: "Su donación <strong>transformará la vida</strong> de alguien.",
        legend: "Apoyo",
      },
      FAQS00001: {
        title: "<strong>Preguntas frecuentes</strong> sobre la donación:",
        cards: [
          {
            title: "¿Programa de Mãos Estendidas acepta donaciones en especie (ropa, alimentos, agua, etc.)?",
            subTitle:
              "Desafortunadamente, no podemos aceptar donaciones en especie.",
          },
          {
            title: "¿Cómo puedo estar seguro de que mi donación en línea ha sido aceptada?",
            subTitle:
              "Si ingresaste una dirección de correo electrónico válida, recibirás un mensaje de confirmación de tu donación. Si no recibes un correo electrónico en 15 minutos, contáctenos.",
          },
          {
            title: "¿Cuáles son los métodos de pago para las donaciones en el sitio web?",
            subTitle:
              "Puedes hacer tu donación, ya sea puntual o recurrente con tarjeta de crédito.",
          },
          {
            title: "¿Por qué la donación recurrente es la mejor opción?",
            subTitle:
              "La donación mensual es la mejor opción tanto para Programa de Mãos Estendidas como para nuestros seguidores. Ella nos permite tener una base de apoyo confiable y proporciona financiamiento predecible. Para nuestros seguidores, es la forma más fácil de donar, ya que la tarjeta de crédito descuenta la donación automáticamente..",
          },
          {
            title:
              "¿Es segura la página de donaciones?",
            subTitle:
              "Estamos orgullosos de cumplir con estrictos estándares de seguridad de datos para procesar tu donación en línea.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Haga una visita a nosotros!`,
        iframeTitle: "Ubicación de la oficina de Programa de Mãos Estendidas"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Programa de Mãos Estendidas® - Todos los derechos pueden ser compartidos con usted.",
        copyrightSpan: "Pregúntanos cómo.",
      },
      SectionCookies: {
        title: "Programa de Mãos Estendidas preocupase contigo e tu privacidad.",
        subTitle:
          "Este sitio web utiliza cookies y otras tecnologias para mejorar y optimizar la experiencia del usuario, bien como para analizar la atividade de los visitantes y su frequência de utilización. Si no aceptas, utilizaremos únicamente las cookies con finalidad técnica, para que la página web funcione.",
        button: "Acepto",
      },
      Checkout: i18n_translations.es.translations,
    }
  }
};

export default i18n;
