import _ from "lodash";
import { useTranslation } from "react-i18next";

import { Wrapper, WrapperTitle, WrapperRow, WrapperColumn } from "./styles";

const Wireframe = () => {
    const { t } = useTranslation();
  
    const rows = t('COMP00025.rows', { returnObjects: true })
    return (
        <Wrapper bgColor="#B35EB7">
            <WrapperTitle color="#FFFFFF">{t("COMP00025.title")}</WrapperTitle>
            { 
                _.map(rows, (item) => (
                    <WrapperRow className="">
                        <WrapperColumn color="#B35EB7" className="column" dangerouslySetInnerHTML={{ __html: t(item.content) }}></WrapperColumn>
                        <div className="icon-container">
                            <img src={`/assets/maos-estendidas/COMP00025-icon.svg`} alt={t(item.alt)} width={256} height={256} />
                        </div>
                    </WrapperRow>
                ))
            }
        </Wrapper>
    )
};

export default Wireframe;