import styled from 'styled-components'
import devices from '../../../utils/helperGrids'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .block-top {
    flex: 1;
    background-image: url(${({ backgroundImageTop }) => backgroundImageTop});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;

    svg {
      fill: ${({ primaryColorTop }) => primaryColorTop};
    }

    @media ${devices.xs} {
      background: #EA00EE;
    }
  }

  .block-bottom {
    flex: 1;
    background-color: ${({ backgroundColorBottom }) => backgroundColorBottom};
    padding-top: 80px;
    padding-bottom: 50px;

    .col-arrow svg {
      margin-: 10px 0;
      transform: rotate(90deg);
      fill: ${({ arrowColorBottom }) => arrowColorBottom};
    }
    
    @media ${devices.lg} {
      .col-arrow svg {
        transform: rotate(0deg);
      }
    }
  }
  
  .textBottom {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    color: #121441;
  }
  
  .row-cards {
    @media ${devices.xs} {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5em;
    }

    @media ${devices.sm} {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5em;
    }

    @media ${devices.md} {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .row-button {
    margin-top: 38px;
  }
`

export const WrapperCard = styled.div`
  width: 265px;
  margin: 0 20px;
  box-shadow: 0px 4px 16px #d3d3d3;
  border-radius: 12px;

  .number {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin-top: -35px;
    margin-left: -10px;
    border-radius: 0 10px 10px 0;
    background-color: ${({ mainColor }) => mainColor};
    color ${({ numberColor }) => numberColor};
    font-size: 20px;
    font-weight: 600;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 265px;
    min-height: 180px;
    padding: 15px;
    border-radius: 10px;
    background-color: ${({ cardBackgroundColor }) => cardBackgroundColor};
    text-align: center;
  }
  .content-description {
    color: ${({ descriptionColor }) => descriptionColor};
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
`

export const WrapperText = styled.div`
  ${({ titleColor }) => titleColor ? `
    h2 {
      margin-bottom: 45px;
      color: ${titleColor};
      font-size: 28px;
      line-height: 32px;
      font-weight: 600;
      text-align: center;
    }
  ` : ''}

  ${({ descriptionColor }) => descriptionColor ? `
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    color: ${descriptionColor};
    margin-bottom: 45px;
  ` : ''}
`

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  
  svg {
    cursor: pointer;
    
    @media ${devices.xs} {
      width: 43px;
    }
    
    @media ${devices.sm} {
      width: 48px;
    }

    @media ${devices.md} {
      width: 57px;
    }
  }

  svg #Caminho_247 {
    fill: ${({ arrowColor }) => arrowColor}
  }

  svg #Elipse_283 {
    stroke: ${({ arrowColor }) => arrowColor};
    fill: transparent;
  }
`