import _ from "lodash";
import {useState} from 'react'
import { Row, Col} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Carousel from "react-spring-3d-carousel";

// Styles
import { Wrapper, WrapperText, WrapperImage, Image } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const [actualSlide, setActualSlide] = useState(0)

  const cardsText = t("COMP00024.cards", { returnObjects: true });

  const slides = _.map(cardsText, (item, index) => {
    return {
      key: index,
      content: (
        <WrapperImage>
          <Image src={`/assets/maos-estendidas/COMP00024-img${index+1}.webp`} width={250} height={250} alt={item.alt} />
        </WrapperImage>
      )
    }
  })

  const getText = () => {
    const index = actualSlide % cardsText.length;
    if(index < 0) {
      return _.get(cardsText, `${index+5}.title`)
    }
    else{
      return _.get(cardsText, `${index}.title`)
    }
  }

  return (
    <Wrapper 
      primaryColor="#121441"
      id="COMP00024"
    >
      <Row className="justify-content-center g-0 h-100">
        <Col xs={11} sm={8} md={6} lg={6} xl={5}>
          <WrapperText titleColor="#121441" dangerouslySetInnerHTML={{ __html: t("COMP00024.title") }}></WrapperText>
        </Col>
      </Row>
      <Row className="justify-content-center g-0 h-100">
        <Col
          className="d-none d-md-flex justify-content-center align-items-center"
          onClick={() => {
            setActualSlide(actualSlide - 1);
          }}
        >
          <FaArrowLeft className="arrow" />
        </Col>
        <Col xs={10} sm={10} md={10} lg={6} xl={6} className='carousel'>
          <Carousel
            goToSlide={actualSlide}
            slides={slides}
            animationConfig={{ tension: 280, friction: 60 }}
          />
        </Col>
        <Col 
          className="d-none d-md-flex justify-content-center align-items-center"
          onClick={() => {
            setActualSlide(actualSlide + 1);
          }}
        >
          <FaArrowRight className="arrow" />
        </Col>
        <div className="d-md-none d-flex flex-row justify-content-between w-50 arrows-xs">
        <div 
          className="d-flex justify-content-center align-items-center" 
          onClick={() => {
            setActualSlide(actualSlide - 1);
          }}
        >
          <FaArrowLeft className="arrow" />
        </div>
        <div
          className="d-md-none d-flex justify-content-center align-items-center"
          onClick={() => {
            setActualSlide(actualSlide + 1);
          }}
        >
          <FaArrowRight className="arrow" />
        </div>
      </div>
      </Row>
      <Row className="justify-content-center g-0 h-100 content">
        <Col xs={10} sm={10} md={10} lg={6} xl={6} className=''>
          <WrapperText
            descriptionColor="#121441"
            dangerouslySetInnerHTML={{ __html: getText() }}
          >
          </WrapperText>
        </Col>
      </Row>
    </Wrapper>
  )
};

export default Wireframe;