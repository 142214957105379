import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import ButtonNoIcon from "../ButtonNoIcon";

// Styles
import { Wrapper, WrapperText } from "./styles";

const SectionCookies = ({ onAccept }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Row className="justify-content-center g-0 h-100">
        <Col xs={10} sm={10} md={10} lg={10} xl={8}>
          <Row className="justify-content-center g-0 h-100">
            <Col xs={12} sm={12} md={9} className="wrapper-content">
              <WrapperText hasTitle={true}>
                {t("SectionCookies.title")}
              </WrapperText>
              <WrapperText hasDescription={true}>
                {t("SectionCookies.subTitle")}
              </WrapperText>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={3}
              className="d-flex justify-content-center align-items-center wrapper-button"
            >
              <ButtonNoIcon
                primaryColor="#FFFFFF"
                secondaryColor="#EA00EE"
                noIcons
                onClick={onAccept}
              >
                {t("SectionCookies.button")}
              </ButtonNoIcon>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SectionCookies;
