import { Image, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import DropdownLocale from "../../DropdownLocale";

// Styles
import { Wrapper } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  return (
    <Wrapper 
      backgroundImageURL="/assets/maos-estendidas/HEAD00004-img.webp" 
      titleColor="#FFFFFF"
    >
      <div>
        <div className="wrapper-button-i18n">
          <div className="d-flex justify-content-center">
            <Col xs={11} md={10} lg={10} xl={8}>
              <DropdownLocale />
            </Col>
          </div>
        </div>
        <div className="d-flex justify-content-center selo">
          <Col md={3} className="imageSelo">
            <Image 
              src="/assets/SELO.webp" 
              width={123} 
              height={139} 
              alt=''
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center logo">
          <Col xs={6} className="d-flex justify-content-center">
            <Image 
              src="/assets/maos-estendidas/HEAD00004-logo.webp" 
              width={270} 
              height={151} 
              alt=''
              fluid 
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center ">
          <Col
            xs={10}
            md={10}
            lg={8}
            xl={6}
            className="text-center title"
            dangerouslySetInnerHTML={{ __html: t("HEAD00004.title") }}
          >
          </Col>
        </div>
        <div className="d-flex justify-content-center ">
          <Col 
            xs={10} 
            md={9} 
            lg={9} 
            xl={8} 
            className="sub-title" 
            dangerouslySetInnerHTML={{ __html: t("HEAD00004.subTitle") }}
          >
          </Col>
        </div>
      </div>
    </Wrapper>
  );
};

export default Wireframe;