import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10em;
  background-image: url(${({ backgroundImageURL }) => backgroundImageURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .logo {
    margin-top: 50px;
    position: relative;
  }

  .selo {
    position: absolute;
    right: 28%;
    top: 7%;

    @media ${devices.xs} {
      right: 7%;
    }

    @media ${devices.sm} {
      right: 15%;
    }

    @media ${devices.md} {
      right: 20%;
    }

    @media ${devices.lg} {
      right: 25%;
    }
  }

  .imageSelo {
    @media ${devices.xs} {
      display: none;
    }

    @media ${devices.sm} {
      height: 100px;
    }

    @media ${devices.md} {
      height: 120px;
    }
  }

  h1 {
    color: ${({ titleColor }) => titleColor};
    font-weight: 900;

    @media ${devices.xs} {
      font-size: 22px;
      margin-top: 30px;
      line-height: 28px;
    }

    @media ${devices.sm} {
      font-size: 26px;
      margin-top: 30px;
      line-height: 33px;
    }

    @media ${devices.md} {
      font-size: 29px;
      margin-top: 40px;
      line-height: 40px;
    }

    @media ${devices.lg} {
      font-size: 32px;
      margin-top: 40px;
      line-height: 40px;
    }

    @media ${devices.xl} {
      font-size: 35px;
      margin-top: 130px;
      line-height: 40px;
    }
  }

  .title {
    color: ${({ titleColor }) => titleColor};
    font-weight: 800;

    @media ${devices.xs} {
      font-size: 20px;
      line-height: 24px;
    }

    @media ${devices.sm} {
      font-size: 24px;
      line-height: 28px;
    }

    @media ${devices.md} {
      font-size: 28px;
      line-height: 32px;
    }

    @media ${devices.lg} {
      font-size: 30px;
      line-height: 34px;
    }

    @media ${devices.xl} {
      font-size: 32px;
      line-height: 36px;
    }
  }

  .sub-title {
    color: ${({ titleColor }) => titleColor};
    font-weight: 500;
    margin-top: 3em;


    @media ${devices.xs} {
      font-size: 15px;
      line-height: 18px;
    }

    @media ${devices.sm} {
      font-size: 16px;
      line-height: 20px;
    }

    @media ${devices.md} {
      font-size: 18px;
      line-height: 22px;
    }

    @media ${devices.lg} {
      font-size: 19px;
      line-height: 24px;
    }
  }
  .button {
    margin-top: 72px;
    margin-bottom: 252px;
  }

  .wrapper-button-i18n {
    position: absolute;
    width: 100%;
    z-index: 1;
    
    @media ${devices.xs} {
      top: 32px;
    }
    @media ${devices.sm} {
      top: 32px;
    }
    @media ${devices.md} {
      top: 50px;
    }
  }
`;