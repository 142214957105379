import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 4em 0; 
  background-color: #121441;

  .button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const WrapperText = styled.div`
  ${({ titleColor }) =>
    titleColor
      ? `
    font-size: 35px;
    line-height: 40px;
    font-weight: 800;
    color: ${titleColor};
    text-align: center;
  `
      : ""}

  ${({ descriptionColor, alignment }) =>
    descriptionColor
      ? `
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    color: ${descriptionColor};
    text-align: ${alignment};
  `
      : ""}
`;